export const cardHeaders = [
  "Exam not Scheduled",
  "Your Olympiad is scheduled on",
  "Your Olympiad starts on",
  "Thank you for participating",
  "Thank you for participating",
];

export const cardBody = [
  "Your Exam date has not been assigned yet",
  "",
  "",
  "Your NFO Level-I results are available for download. Click to download",
  "Your NFO Level-I results will be announced soon",
];

export const cardBG = [
  "rgba(255, 171, 46, 0.2)",
  "#bbd2f552",
  "rgba(255, 171, 46, 0.2)",
  "rgba(188, 255, 46, 0.2)",
  "rgba(188, 255, 46, 0.2)",
];

export const cardColor = [
  "rgba(170, 58, 21, 1)",
  "#2c6ab1",
  "rgba(170, 58, 21, 1)",
  "rgba(15, 102, 8, 1)",
  "rgba(15, 102, 8, 1)",
];

export const button1Txt = [
  "Contact Support",
  "View Exam Rules",
  "Start Olympiad",
  "Certificate",
  "",
];

export const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "2-digit",
  month: "short",
  year: "numeric",
};

export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};
