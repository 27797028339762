import React, { useContext } from "react";
import { useNavigate } from "react-router";
import "./NFOSessionCard.css";
import DoubtClearingBadge1 from "../../../../Assets/lotties/DoubtClearingBadge1.json";
import Lottie from "lottie-react";

import { AuthContext } from "../../../../App";

const NFOSessionCard = () => {
  const {
    auth: { sessionBooked },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const imageUrl =
    "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/Icons/Mask+group+(1).png";

  return (
    <div className="ns-card">
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <Lottie
          style={{
            height: "30px",
            width: "110px",
          }}
          animationData={DoubtClearingBadge1}
          loop={true}
        />
      </div>

      <div className="ns-content">
        <img className="ns-img" alt="Img" src={imageUrl} />
        <div className="ns-heading">NFO - Live Training Classes</div>
        <div className="ns-subheading">
          Book live classes with our expert tutors to master complex topics with
          ease and prepare for your Olympiad.
        </div>

        <div
          style={{
            marginTop: "15px",

            gap: "10px",
          }}
        >
          <div
            className="ns-button"
            onClick={() =>
              sessionBooked
                ? navigate("/nfo-recorded")
                : navigate("/nfo-recorded")
            }
          >
            <div className="ns-button-txt">View</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFOSessionCard;
