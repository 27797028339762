import React, { useEffect, useRef } from "react";
import "./VideoCard.css";

interface YouTubePlayerProps {
  videoId: string;
  autoplay?: boolean;
  loop?: boolean;
  mute?: boolean;
  controls?: number;
  showInfo?: number;
  rel?: number;
  playlist?: string;
  play: boolean;
  pause: boolean;
  seekSeconds: number;
  currentTime: (time: number) => void;
  videoDuration: (duration: number) => void;
  onVideoEnd: () => void;
  playbackSpeed?: number;
  rewind: boolean;
  forward: boolean;
  replay?: boolean;
  style?: React.CSSProperties;
  isPlaying: (playing: boolean) => void;
}

declare global {
  interface Window {
    onYouTubeIframeAPIReady?: () => void;
    YT: {
      PlayerState: any;
      Player: new (
        elementId: string,
        options: {
          videoId: string;
          playerVars?: Record<string, any>;
          events: {
            onReady?: (event: YTPlayerEvent) => void;
            onStateChange?: (event: YTPlayerEvent) => void;
          };
        }
      ) => YTPlayer;
    };
  }
}

interface YTPlayer {
  playVideo: () => void;
  pauseVideo: () => void;
  cueVideoById: (videoId: string, startSeconds?: number) => void;
  seekTo: (seconds: number, allowSeekAhead: boolean) => void;
  destroy: () => void;
  mute: () => void;
  unMute: () => void;
  getCurrentTime: () => number;
  getDuration: () => number;
  setPlaybackRate: (rate: number) => void;
  getPlaybackRate: () => number;
}

interface YTPlayerEvent {
  data: any;
  target: YTPlayer;
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({
  videoId,
  autoplay = true,
  loop = false,
  mute = false,
  controls = 0,
  showInfo = 0,
  rel = 0,
  playlist,
  play,
  pause,
  seekSeconds,
  currentTime,
  videoDuration,
  onVideoEnd,
  playbackSpeed = 1,
  rewind,
  forward,
  replay,
  style,
  isPlaying,
}) => {
  const playerRef = useRef<YTPlayer | null>(null);
  const playerId = useRef(`player-${Math.random().toString(36).substr(2, 9)}`); // Unique ID for each player
  const apiReadyRef = useRef(false);

  useEffect(() => {
    console.log("Initializing YouTube player with videoId:", videoId);

    if (
      !document.querySelector(
        'script[src="https://www.youtube.com/iframe_api"]'
      )
    ) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }

    window.onYouTubeIframeAPIReady = () => {
      console.log("YouTube IFrame API is ready.");
      apiReadyRef.current = true;
      createPlayer();
    };

    if (window.YT && window.YT.Player) {
      apiReadyRef.current = true;
      createPlayer();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      // Clear any intervals or event listeners here
    };
  }, []); // Run only once on mount

  useEffect(() => {
    if (apiReadyRef.current) {
      createPlayer();
    }
  }, [videoId]); // Re-run effect when videoId changes

  const createPlayer = () => {
    if (document.getElementById(playerId.current)) {
      console.log("Player element found, creating player...");
      if (playerRef.current) {
        playerRef.current.destroy(); // Destroy existing player if any
      }
      playerRef.current = new window.YT.Player(playerId.current, {
        videoId: videoId,
        playerVars: {
          autoplay: autoplay ? 1 : 0,
          controls,
          showinfo: showInfo,
          rel: rel,
          loop: loop ? 1 : 0,
          modestbranding: 1,
          playlist: loop && playlist ? playlist : undefined,
        },
        events: {
          onReady: (event) => {
            console.log("Player is ready with videoId:", videoId);
            playerRef.current = event.target; // Ensure playerRef is set correctly
            console.log(
              "Player instance assigned to playerRef:",
              playerRef.current
            );
            updateVideoDuration();
            if (autoplay) event.target.playVideo();

            // Get and log the current playback speed
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.ENDED) {
              if (loop) {
                event.target.playVideo();
              } else {
                event.target.cueVideoById(videoId);
                onVideoEnd();
              }
            }
            if (event.data === window.YT.PlayerState.PLAYING) {
              isPlaying(true);
            } else if (
              event.data === window.YT.PlayerState.PAUSED ||
              event.data === window.YT.PlayerState.ENDED
            ) {
              isPlaying(false);
            }
          },
        },
      });
    } else {
      console.error("Player element not found.");
    }
  };

  const updateVideoDuration = () => {
    if (playerRef.current) {
      // Delay the retrieval of the duration to ensure metadata is loaded
      setTimeout(() => {
        const duration = playerRef.current?.getDuration();
        console.log("Retrieved video duration after delay:", duration);

        if (duration && !isNaN(duration)) {
          console.log("Updated video duration:", duration);
          videoDuration(duration);
        } else {
          console.warn("Video duration is still undefined or NaN after delay");
        }
      }, 1000); // 1000ms delay
    } else {
      console.error("Player reference is null");
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      console.log("Player reference is valid:", playerRef.current);
      if (
        typeof playerRef.current.playVideo === "function" &&
        typeof playerRef.current.pauseVideo === "function"
      ) {
        if (play) {
          playerRef.current.playVideo();
        } else if (pause) {
          playerRef.current.pauseVideo();
        }
      } else {
        console.error("Player methods are not available.");
      }
    } else {
      console.error(
        "Player reference is null or invalid when trying to play/pause."
      );
    }
  }, [play, pause]);

  useEffect(() => {
    if (rewind) {
      rewindVideo();
    }
  }, [rewind]);

  useEffect(() => {
    if (forward) {
      forwardVideo();
    }
  }, [forward]);

  const rewindVideo = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      if (currentTime > 10) {
        playerRef.current.seekTo(currentTime - 10, true);
        playerRef.current.playVideo();
      } else {
        playerRef.current.pauseVideo();
        console.log(
          "Try to rewind video when current playing time greater than 10."
        );
      }
    } else {
      console.error("Player reference is null or invalid.");
    }
  };

  const forwardVideo = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      if (currentTime < duration - 10) {
        playerRef.current.seekTo(currentTime + 10, true);
        playerRef.current.playVideo();
      } else {
        playerRef.current.pauseVideo();
        console.log("Cannot forward video when less than 10 seconds remain.");
      }
    } else {
      console.error("Player reference is null or invalid.");
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      if (mute) {
        if (typeof playerRef.current.mute === "function") {
          playerRef.current.mute();
        } else {
          console.error("Mute method is not available.");
        }
      } else {
        if (typeof playerRef.current.unMute === "function") {
          playerRef.current.unMute();
        } else {
          console.error("Unmute method is not available.");
        }
      }
    } else {
      console.error(
        "Player reference is null or invalid when trying to mute/unmute."
      );
    }
  }, [mute]);

  // Update current time periodically
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (play) {
      interval = setInterval(() => {
        if (playerRef.current) {
          currentTime(playerRef.current.getCurrentTime());
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Clear interval on unmount
      }
    };
  }, [play]);

  // Ensure the API is loaded before creating the player
  useEffect(() => {
    if (!window.YT || !window.YT.Player) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        console.log("YouTube IFrame API is ready.");
        apiReadyRef.current = true;
        createPlayer();
      };
    } else {
      apiReadyRef.current = true;
      createPlayer();
    }
  }, []);

  useEffect(() => {
    if (
      playerRef.current &&
      typeof playerRef.current.setPlaybackRate === "function"
    ) {
      const currentPlaybackSpeed = playerRef.current.getPlaybackRate();
      console.log("Current playback speed:", currentPlaybackSpeed);
      if (currentPlaybackSpeed !== playbackSpeed && playbackSpeed <= 2) {
        playerRef.current.setPlaybackRate(playbackSpeed);
        console.log("Set playback speed to:", playbackSpeed);
      }
    } else {
      console.error(
        "Player reference is null or setPlaybackRate method is not available."
      );
    }
  }, [playbackSpeed]);
  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e: any) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    if (replay && playerRef.current) {
      console.log("Replaying video from the start.");
      playerRef.current.seekTo(0, true); // Seek to the start of the video
      playerRef.current.playVideo(); // Play the video
    }
  }, [replay]);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
        style={style}
        className="video-container"
      >
        <div
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          id={playerId.current}
        />
      </div>
    </div>
  );
};

export default YouTubePlayer;
