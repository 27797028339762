import React, { FC, ReactNode, useState, useEffect } from "react";
import "./Modal.css";

interface ModalProps {
  isOpen: boolean;
  closable?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const VideoModal: FC<ModalProps> = ({
  isOpen,
  closable = false,
  onClose,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  const handleBackdropClick = () => {
    if (closable) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <div
      className={`modal-backdrop ${isOpen ? "fade-in" : "fade-out"}`}
      onClick={handleBackdropClick}
    >
      <div className="video-modal-content" onClick={(e) => e.stopPropagation()}>
        {closable && (
          <span className="close-button" onClick={onClose}>
            &times;
          </span>
        )}

        {children}
      </div>
    </div>
  );
};

export default VideoModal;
