import React, { useContext } from "react";
import { arrowLeftWhite } from "../../../../Assets/images";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../App";
import Modal from "../../../../Components/modal/Modal";

interface accessProductPropsInterface {
  cbHandleCloseModal: () => void;
  openModal: boolean;
  currentDescription: string;
  sellingPrice: string;
  basePrice: string;
  imgSrc: string;
  onPress: () => void;
  buttonText: string;
  itemName: string;
}

const PreviewModal = (props: accessProductPropsInterface) => {
  return (
    <Modal
      onClose={props.cbHandleCloseModal}
      isOpen={props.openModal}
      closable={true}
    >
      <div>
        <div className="item-info-container">
          <img
            src={props.imgSrc}
            alt={props.itemName}
            style={{
              height: 130,
              width: 130,
              alignSelf: "center",
              objectFit: "contain",
            }}
          />
          <div className="product-question">{props.itemName}</div>

          <div style={{ paddingTop: 10, fontSize: 18, textAlign: "center" }}>
            {props.currentDescription}
          </div>
          <div style={{ paddingTop: 10, textAlign: "center" }}>
            Price: <del>₹{props.basePrice}</del> ₹{props.sellingPrice}
          </div>
          <div
            onClick={props.onPress}
            style={{
              backgroundColor: "#2C6AB1",
              color: "#fff",
              fontSize: 18,
              borderRadius: 100,
              alignSelf: "center",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              cursor: "pointer",
            }}
          >
            {props.buttonText}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
