import React, { useRef, useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import { CustomDatePicker } from "../../Components/InputField";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";

import NFOSessionHeader from "./NFOSessinComponents/NFOSessionHeader";
import SessionDetails from "./NFOSessinComponents/SessionDetails";
import ErrorModal from "./NFOSessinComponents/ErrorModal";

import { AuthContext } from "../../App";
import {
  SessionsAvailableData,
  getPreOlympiadSlots,
} from "../../Utils/api/auth";
import "./NFOSession.css";
import { Margin } from "@mui/icons-material";

const ERROR_TIMEOUT = 5000;
const ITEM_ID = 29;


const NFOSession = () => {
  const {
    auth: { student_username, sessionBooked },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const [sessoinsAvailable, setSessoinsAvailable] = useState<
    SessionsAvailableData["data"]
  >([]);
  const [sessionDates, setSessionDates] = useState<string[]>([]);
  const [datesAvailable, setDatesAvailable] = useState<boolean>(false);
  const [batchDetails, setBatchDetails] = useState<
    SessionsAvailableData["data"][0] | null
  >(null);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [goToProceed, setGoToProceed] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [hasProceed, setHasProceed] = useState<boolean>(false);
  const [errorProceed, setErrorProceed] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  const proceedContainerRef = useRef<HTMLDivElement>(null);

  const handleDateSelect = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDate(formattedDate);
    setBatchDetails(
      sessoinsAvailable.find(
        (session: any) =>
          session.batch_start_time.split("T")[0] === formattedDate
      ) || null
    );
  };

  const handleProced = async () => {
    setHasProceed(true);
    try {
      const token = localStorage.getItem("accessToken");
      if (token && batchDetails?.event_id) {
        openInSameTab(
          `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${ITEM_ID}&token=${token}&userName=${student_username}&eventId=${batchDetails?.event_id}`
        );
      } else handleErrorProceed();
      // setTimeout(() => {
      //   handleErrorProceed()
      // }, 500);
    } catch (error) {
      // console.error(error);
      handleErrorProceed();
    }
  };

  const handleErrorProceed = () => {
    setErrorProceed(true);
    setSelectedDate("");
    setHasProceed(false);
    onRefresh();

    setTimeout(() => {
      setErrorProceed(false);
    }, ERROR_TIMEOUT);
  };

  const getSessions = async () => {
    try {
      setLoading(true);
      const res = await getPreOlympiadSlots();
      const data = res.data.data;
      setSessoinsAvailable(data);
      if (typeof data !== "string" && data.length > 0) {
        setSessionDates(
          data.map((batch) => batch.batch_start_time.split("T")[0])
        );
        setDatesAvailable(true);
      } else {
        setDatesAvailable(false);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const openInSameTab = (url: string) => (window.location.href = url);
  const onRefresh = () => setRefresh((prev) => !prev);

  const handleReset = () => {
    setSelectedDate("");
    setIsDateSelected(false);
    setBatchDetails(null);
  };

  useEffect(() => {
    getSessions();
  }, [refresh]);

  useEffect(() => {
    // don't remove it.. need some time to slide in the proceed container
    setTimeout(() => {
      if (window.innerWidth <= 768 && proceedContainerRef.current) {
        proceedContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 600);
  }, [goToProceed]);

  return (
    <>
      <ErrorModal display={errorProceed} />
      <div className="ns-page">
        <NFOSessionHeader />
        <div className="ns-page-body">
          <div className="ns-container">
            <p className="ns-container-heading">
              Choose a start date for your sessions
            </p>
            <div className="ns-datepicker-container">
              {loading ? (
                <div style={{ width: "fit-content", margin: "auto" }}>
                  <CircularProgress size={25} />
                </div>
              ) : sessionBooked ? (
                <p>
                  You have already enrolled for NFO - Live Training Classes.
                  Please visit&nbsp;
                  <span
                    className="link"
                    onClick={() => navigate("/nfo-classes-history")}
                  >
                    <em>your classes</em>
                  </span>
                  &nbsp;for more details.
                </p>
              ) : (
                <>
                  {!datesAvailable ? (
                    <div className="ns-datefield">
                      No Classes available right now
                    </div>
                  ) : !selectedDate ? (
                    <div className="ns-datefield">
                      Click here to choose a date
                    </div>
                  ) : (
                    <div className="ns-datefield">
                      {format(selectedDate, "EEEE")} batch
                    </div>
                  )}
                  <CustomDatePicker
                    selectableDates={sessionDates}
                    onChange={handleDateSelect}
                    value={selectedDate}
                    disabled={!datesAvailable}
                  />
                </>
              )}
            </div>
            <AnimatePresence>
              {batchDetails && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height: selectedDate ? "auto" : 0,
                    opacity: selectedDate ? 1 : 0,
                  }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    ease: "easeInOut",
                    duration: 0.3,
                  }}
                >
                  <p className="batch-details-head">Session Schedule</p>
                  {batchDetails.slot_details.map(
                    (batch: any, index: number) => (
                      <div key={index}>
                        <div className="line"></div>
                        <div className="ns-batch-details">
                          <p>
                            <span>Day {index + 1}</span>
                            &nbsp;&nbsp;
                            <span>
                              {format(batch.batch_start_time, "EEEE, LLLL dd")}
                            </span>
                          </p>
                          <p
                            className="text-depressed"
                            style={{ width: "fit-content", marginLeft: "auto" }}
                          >
                            <span>
                              {format(batch.batch_start_time, "hh:mm a")}
                            </span>
                            <span> - </span>
                            <span>
                              {format(batch.batch_end_time, "hh:mm a")}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                  {window.innerWidth <= 768 && (
                    <button
                      className="ns-btn"
                      onClick={() => {
                        setIsDateSelected(true);
                        setGoToProceed((prev) => !prev);
                      }}
                    >
                      Confirm session
                    </button>
                  )}
                  {/* <button onClick={handleReset}>reset</button> */}
                </motion.div>
              )}
            </AnimatePresence>
            <SessionDetails />
          </div>
          <AnimatePresence>
            {(isDateSelected || (window.innerWidth > 768 && selectedDate)) && (
              <motion.div
                className="ns-container ns-proceed-container shadow-light"
                ref={proceedContainerRef}
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: "0%" }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{
                  type: "just",
                  delay: 0.3,
                  stiffness: 90,
                }}
              >
                <p className="ns-proceed-heading">
                  Sessions by NFO starts from
                  <p className="ns-proceed-subheading">
                    {format(new Date(selectedDate), "eeee, LLLL dd")}
                  </p>
                </p>
                <div className="line"></div>
                <div className="ns-proceed-body">
                  <p className="ns-proceed-price">
                    <span>Subtotal (1 item):</span>
                    <span>&#8377;1499</span>
                  </p>
                  <span className="text-depressed">inclusive of all taxes</span>
                </div>
                <button
                  type="button"
                  className="ns-proceed-button"
                  disabled={hasProceed}
                  onClick={handleProced}
                >
                  {hasProceed ? (
                    <div
                      style={{
                        width: "fit-content",
                        height: "25px",
                        margin: "auto",
                        color: "#fff",
                      }}
                    >
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    "Proceed"
                  )}
                </button>
                <p className="ns-proceed-footer text-depressed">
                  You will gain access to the session link on the registered
                  session date
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default NFOSession;
