import React, { FunctionComponent } from "react";
import { certificateItems } from "../../../../Utils/api/auth";

interface FinalReportProps {
  id: string;
  data?: certificateItems;
  individualRegistration: boolean;
}

const FinalReport: FunctionComponent<FinalReportProps> = ({
  id,
  data,
  individualRegistration,
}) => {
  const getExamDateFromUnix = (dateValue: Date) => {
    let date = new Date(dateValue);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Format the date as "DD Month YYYY"
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  function convertTimeToMinutes(timeStr: string) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);

    // Convert the total time into minutes
    const totalMinutes = hours * 60 + minutes;

    // Return the formatted string with total minutes and seconds
    return { time: { minutes: minutes, seconds: seconds } };
  }

  return (
    <div
      id={id}
      style={{
        width: "595px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          alignSelf: "stretch",
          backgroundColor: "#fefeff",
          border: "10px solid #28398b",
          boxSizing: "border-box",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "117px 32px 14px 34px",
          gap: "29px",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "25.6px",
          color: "#28398b",
          fontFamily: "Inika",
          height: "840px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "0px 0px 19.8px",
            color: "rgba(40, 57, 139, 0.9)",
          }}
        >
          <div style={{ position: "relative", lineHeight: "26px" }}>
            Report Card
          </div>
        </div>
        <div
          style={{
            width: "317.6px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "0px 2px",
            boxSizing: "border-box",
            fontSize: "15.9px",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "10.4px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2.6px",
                color: "#464646",
                fontFamily: "Cardo",
              }}
            >
              <nav
                style={{
                  margin: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "10.5px",
                  textAlign: "left",
                  fontSize: "15.9px",
                  color: "#28398b",
                  fontFamily: "Inika",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "48px",
                  }}
                >
                  Name:
                </b>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "48px",
                  }}
                >
                  Grade:
                </b>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "52px",
                  }}
                >
                  School:
                </b>
              </nav>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "10.5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      height: "205px",
                      width: "562.5px",
                      position: "absolute",
                      margin: "0",
                      top: "-197.9px",
                      right: "-405.9px",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: "32.3px",
                        left: "12px",
                        width: "127px",
                        height: "56.4px",
                      }}
                      loading="lazy"
                      alt=""
                      src={require("./cert-assets/vector.png")}
                    />
                    <img
                      style={{
                        position: "absolute",
                        height: "100%",
                        top: "0px",
                        bottom: "0px",
                        left: "340px",
                        maxHeight: "100%",
                        width: "222.5px",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                      alt=""
                      src={require("./cert-assets/group-13062@2x.png")}
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "164.5px",
                        left: "0px",
                        width: "527.5px",
                        height: "1px",
                        zIndex: "1",
                      }}
                      alt=""
                      src={require("./cert-assets/vector-3161.png")}
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "111.5px",
                        left: "0px",
                        width: "367.5px",
                        height: "1px",
                        zIndex: "1",
                      }}
                      alt=""
                      src={require("./cert-assets/vector-3161.png")}
                    />
                  </div>
                  <b
                    style={{
                      position: "relative",
                      lineHeight: "101%",
                      fontWeight: "700",
                      display: "inline-block",
                      minWidth: "88px",
                    }}
                  >
                    {data?.data.name}
                  </b>
                </div>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "26px",
                  }}
                >
                  {data?.data.grade}
                </b>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0px 0px 0px 4px",
                  }}
                >
                  <b style={{ position: "relative", lineHeight: "101%" }}>
                    {data?.data.school}
                  </b>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "6.6px",
              }}
            >
              <b
                style={{
                  position: "relative",
                  lineHeight: "101%",
                  fontWeight: "700",
                  display: "inline-block",
                  minWidth: "33px",
                }}
              >
                City:
              </b>
              <b
                style={{
                  position: "relative",
                  lineHeight: "101%",
                  fontWeight: "700",
                  fontFamily: "Cardo",
                  color: "#464646",
                  display: "inline-block",
                  minWidth: "65px",
                }}
              >
                {data?.data.city}
              </b>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "6.6px",
              }}
            >
              <b
                style={{
                  position: "relative",
                  lineHeight: "101%",
                  fontWeight: "700",
                  display: "inline-block",
                  minWidth: "40px",
                }}
              >
                Zone:
              </b>
              <b
                style={{
                  position: "relative",
                  lineHeight: "101%",
                  fontWeight: "700",
                  fontFamily: "Cardo",
                  color: "#464646",
                  display: "inline-block",
                  minWidth: "41px",
                }}
              >
                {data?.data.zone}
              </b>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 0px 3.6px",
                gap: "12.9px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "6.6px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "97px",
                  }}
                >
                  Roll Number:
                </b>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    fontFamily: "Cardo",
                    color: "#464646",
                    display: "inline-block",
                    minWidth: "49px",
                  }}
                >
                  {data?.data.studentUsername}
                </b>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "6.6px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "101px",
                  }}
                >
                  Awards Won:
                </b>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    fontFamily: "Cardo",
                    color: "#464646",
                  }}
                >
                  {data?.data.awardWon}
                </b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "6.6px",
              }}
            >
              <b style={{ position: "relative", lineHeight: "101%" }}>
                Date of Olympiad:
              </b>
              <b
                style={{
                  position: "relative",
                  lineHeight: "101%",
                  display: "inline-block",
                  fontFamily: "Cardo",
                  color: "#464646",
                  minWidth: "91px",
                }}
              >
                {data?.data.examDate &&
                  getExamDateFromUnix(data?.data.examDate)}
              </b>
            </div>
            {individualRegistration && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "6.6px",
                }}
              >
                <b style={{ position: "relative", lineHeight: "101%" }}>
                  Batch Size:
                </b>
                <b
                  style={{
                    position: "relative",
                    lineHeight: "101%",
                    display: "inline-block",
                    fontFamily: "Cardo",
                    color: "#464646",
                    minWidth: "91px",
                  }}
                >
                  {data?.data.participatedStudentCount}
                </b>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: "509px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "0px 1px 0px 2px",
            boxSizing: "border-box",
            maxWidth: "100%",
            fontSize: "13.5px",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0px 0px 58.1px",
              boxSizing: "border-box",
              gap: "26.9px",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  flex: "1",
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                }}
                loading="lazy"
                alt=""
                src={require("./cert-assets/rectangle-3654.png")}
              />
            </div>
            <div
              style={{
                width: "456px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 66px 0px 0px",
                boxSizing: "border-box",
                gap: "33.1px",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",

                  justifyContent: "space-between",
                  gap: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "9.6px",
                    width: 200,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                      minWidth: "69px",
                    }}
                  >
                    Your Score
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "57.7px",
                      lineHeight: "101%",
                      fontFamily: "Cardo",
                      color: "rgba(0, 0, 0, 0.9)",
                    }}
                  >
                    {data?.data.scoreAnalysis.marksObtained}/
                    {data?.data.scoreAnalysis.fullMarks}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "9.6px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                      minWidth: "100px",
                    }}
                  >
                    Percentage Score
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "57.7px",
                      lineHeight: "101%",
                      fontFamily: "Cardo",
                      color: "rgba(0, 0, 0, 0.9)",
                    }}
                  >
                    {data?.data.scoreAnalysis.percentage}%
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  gap: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "9.6px",
                    width: 200,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                      minWidth: "107px",
                    }}
                  >
                    Completion Time
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      gap: "9.9px",
                      fontSize: "48.1px",
                      color: "rgba(0, 0, 0, 0.9)",
                      fontFamily: "Cardo",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        position: "relative",
                        lineHeight: "49px",
                      }}
                    >
                      {data &&
                        `${
                          convertTimeToMinutes(
                            data?.data.scoreAnalysis.timeTakenForExam
                          ).time.minutes
                        } m ${
                          convertTimeToMinutes(
                            data?.data.scoreAnalysis.timeTakenForExam
                          ).time.seconds
                        } s`}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "9.6px",
                    width: "150px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                    }}
                  >
                    Rank
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "48.1px",
                      lineHeight: "49px",
                      fontFamily: "Cardo",
                      color: "rgba(0, 0, 0, 0.9)",
                      display: "inline-block",
                      minWidth: "75px",
                    }}
                  >
                    {data?.data.scoreAnalysis.batchRank}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0px 0px 0px 0px",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "9.6px",
                    width: 300,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                      minWidth: "125px",
                    }}
                  >
                    Batch Highest Score
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "48.1px",
                      lineHeight: "49px",
                      fontFamily: "Cardo",
                      color: "rgba(0, 0, 0, 0.9)",
                      display: "inline-block",
                      minWidth: "50px",
                    }}
                  >
                    {data?.data.scoreAnalysis.batchHighestScore}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "9.6px",
                    marginLeft: 175,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "14px",
                      display: "inline-block",
                      minWidth: "124px",
                    }}
                  >
                    Batch Average Percentage
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "48.1px",
                      lineHeight: "49px",
                      fontFamily: "Cardo",
                      color: "rgba(0, 0, 0, 0.9)",
                      display: "inline-block",
                      minWidth: "128px",
                    }}
                  >
                    {data?.data.scoreAnalysis.batchAverageScore}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          alignSelf: "stretch",
          backgroundColor: "#fefeff",
          border: "10px solid #28398b",
          boxSizing: "border-box",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "12px 32px 25px",
          gap: "23.6px",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "19.9px",
          color: "rgba(0, 0, 0, 0.9)",
          fontFamily: "Cardo",
          height: "842px",
        }}
      >
        <div
          style={{
            width: "506px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "20px",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "12.8px 0px 0px",
            }}
          >
            <b style={{ position: "relative", lineHeight: "101%" }}>
              Topic Wise Analysis
            </b>
          </div>
          <img
            style={{ height: "42.5px", width: "97.5px", position: "relative" }}
            alt=""
            src={require("./cert-assets/vector.png")}
          />
        </div>
        <div
          style={{
            width: "511px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
            maxWidth: "100%",
            fontSize: "11.8px",
            color: "#fff",
            marginBottom: "3px",
          }}
        >
          {data?.data.topicWiseAnalysis.map((e, index) => {
            return (
              <div
                key={e.topicName}
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6.72px",
                  backgroundColor: "rgba(40, 57, 139, 0.05)",
                  border: "0.8px solid rgba(0, 0, 0, 0.1)",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "11px 10px 8px",
                  gap: "2px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    width: "478.3px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0px 2px 5.7px",
                    boxSizing: "border-box",
                    maxWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "25.4px",
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "4.48px",
                        backgroundColor: "rgb(40, 57, 139,0.1)",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "4.5px 7px",
                        width: "135px",
                      }}
                    >
                      <b
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "700",
                          display: "inline-block",
                          minWidth: "49px",
                          color: "rgb(40, 57, 139,1)",
                          textAlign: "start",
                        }}
                      >
                        {index + 1}. {e.topicName}
                      </b>
                    </div>
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        minWidth: "191px",
                        maxWidth: "100%",
                        gap: "20px",
                        fontSize: "10.4px",
                        color: "rgba(40, 57, 139, 0.8)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: "3px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            lineHeight: "14px",
                            display: "inline-block",
                            minWidth: "54px",
                          }}
                        >
                          Total Marks
                        </div>
                        <div
                          style={{
                            position: "relative",
                            fontSize: "18.7px",
                            lineHeight: "24px",
                            color: "#28398b",
                            display: "inline-block",
                            minWidth: "50px",
                          }}
                        >
                          {e.fullMarks}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: "3px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            lineHeight: "14px",
                            display: "inline-block",
                            minWidth: "60px",
                          }}
                        >
                          Your Score
                        </div>
                        <div
                          style={{
                            position: "relative",
                            fontSize: "18.7px",
                            lineHeight: "24px",
                            color: "#28398b",
                            display: "inline-block",
                            minWidth: "36px",
                          }}
                        >
                          {e.marksObtained}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: "3px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            lineHeight: "14px",
                            display: "inline-block",
                            minWidth: "49px",
                          }}
                        >
                          Percentage
                        </div>
                        <div
                          style={{
                            position: "relative",
                            fontSize: "18.7px",
                            lineHeight: "24px",
                            color: "#28398b",
                            display: "inline-block",
                            minWidth: "50px",
                          }}
                        >
                          {e.percentage}%
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: "3.1px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            lineHeight: "14px",
                            display: "inline-block",
                            minWidth: "27px",
                          }}
                        >
                          Grade
                        </div>
                        <div
                          style={{
                            position: "relative",
                            fontSize: "18.7px",
                            lineHeight: "24px",
                            color: "#28398b",
                            display: "inline-block",
                            minWidth: "13px",
                          }}
                        >
                          {e.grade}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    maxWidth: "100%",
                    overflow: "hidden",
                    maxHeight: "100%",
                  }}
                  loading="lazy"
                  alt=""
                  src={require("./cert-assets/rectangle-3654.png")}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0px 2px",
                    boxSizing: "border-box",
                    maxWidth: "100%",
                    fontSize: "14.1px",
                    color: "#28398b",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      lineHeight: "128.3%",
                      width: "100%",
                    }}
                  >
                    Remarks: {e.remarks}
                  </div>
                </div>
              </div>
            );
          })}
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "6.72px",
              backgroundColor: "rgba(40, 57, 139, 0.05)",
              border: "0.8px solid rgba(0, 0, 0, 0.1)",
              boxSizing: "border-box",
              overflow: "hidden",
              display: data && data.data.grade < 11 ? "flex" : "none",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "11px 10px 8px",
              gap: "11px",
              maxWidth: "100%",
              opacity: 0,
            }}
          >
            <div
              style={{
                width: "478.3px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 2px 5.7px",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "25.4px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "4.48px",
                    backgroundColor: "#28398b",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "4.5px 7px",
                    width: "100px",
                  }}
                >
                  <b
                    style={{
                      position: "relative",
                      lineHeight: "12px",
                      fontWeight: "700",
                      display: "inline-block",
                      minWidth: "51px",
                    }}
                  >
                    {1}. {"Money"}
                  </b>
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    minWidth: "191px",
                    maxWidth: "100%",
                    gap: "20px",
                    fontSize: "10.4px",
                    color: "rgba(40, 57, 139, 0.8)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "3px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "14px",
                        display: "inline-block",
                        minWidth: "54px",
                      }}
                    >
                      Total Marks
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "18.7px",
                        lineHeight: "24px",
                        color: "#28398b",
                        display: "inline-block",
                        minWidth: "50px",
                      }}
                    >
                      {"fullMarks"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "3px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "14px",
                        display: "inline-block",
                        minWidth: "60px",
                      }}
                    >
                      Marks Scored
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "18.7px",
                        lineHeight: "24px",
                        color: "#28398b",
                        display: "inline-block",
                        minWidth: "36px",
                      }}
                    >
                      {"marksObtained"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "3px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "14px",
                        display: "inline-block",
                        minWidth: "49px",
                      }}
                    >
                      Percentage
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "18.7px",
                        lineHeight: "24px",
                        color: "#28398b",
                        display: "inline-block",
                        minWidth: "50px",
                      }}
                    >
                      {"percentage"}%
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "3.1px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "14px",
                        display: "inline-block",
                        minWidth: "27px",
                      }}
                    >
                      Grade
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "18.7px",
                        lineHeight: "24px",
                        color: "#28398b",
                        display: "inline-block",
                        minWidth: "13px",
                      }}
                    >
                      {"grade"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              style={{
                alignSelf: "stretch",
                position: "relative",
                maxWidth: "100%",
                overflow: "hidden",
                maxHeight: "100%",
              }}
              loading="lazy"
              alt=""
              src={require("./cert-assets/rectangle-3654.png")}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 2px",
                boxSizing: "border-box",
                maxWidth: "100%",
                fontSize: "14.1px",
                color: "#28398b",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  lineHeight: "128.3%",
                }}
              >
                Remarks:{"remarks"}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          alignSelf: "stretch",
          backgroundColor: "#fefeff",
          border: "10px solid #28398b",
          boxSizing: "border-box",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "12px 30px 18px",
          gap: "14px",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "21.4px",
          color: "rgba(0, 0, 0, 0.9)",
          fontFamily: "Cardo",
          height: "842px",
        }}
      >
        <div
          style={{
            width: "515px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "0px 2px 0px 1px",
            boxSizing: "border-box",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "23.6px",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 5px 0px 1px",
                boxSizing: "border-box",
                maxWidth: "100%",
                flexShrink: "0",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    width: "186.2px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "12.8px 0px 0px",
                    boxSizing: "border-box",
                  }}
                >
                  <b
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      lineHeight: "22px",
                      fontWeight: "700",
                    }}
                  >
                    Batch Analysis
                  </b>
                </div>
                <img
                  style={{
                    height: "42.5px",
                    width: "97.5px",
                    position: "relative",
                  }}
                  alt=""
                  src={require("./cert-assets/vector.png")}
                />
              </div>
            </div>

            {data?.data.batchTopicWiseAnalysis.map((e, index) => {
              return (
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "17px",
                    maxWidth: "100%",
                    flexShrink: "0",
                    fontSize: "11.8px",
                    color: "#fff",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 0px 1px",
                      boxSizing: "border-box",
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        borderRadius: "6.72px",
                        backgroundColor: "rgba(40, 57, 139, 0.05)",
                        border: "0.8px solid rgba(0, 0, 0, 0.1)",
                        boxSizing: "border-box",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "11px 10px 8px",
                        gap: "9.8px",
                        maxWidth: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "478.3px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          padding: "0px 2px 5.7px",
                          boxSizing: "border-box",
                          maxWidth: "100%",
                        }}
                      >
                        <div
                          style={{
                            flex: "1",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "25.4px",
                            maxWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              borderRadius: "4.48px",
                              backgroundColor: "rgb(40, 57, 139,0.1)",
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              padding: "4.5px 7px",
                              width: "135px",
                            }}
                          >
                            <b
                              style={{
                                position: "relative",
                                lineHeight: "16px",
                                fontWeight: "700",
                                display: "inline-block",
                                minWidth: "49px",
                                color: "rgb(40, 57, 139,1)",
                                textAlign: "start",
                              }}
                            >
                              {index + 1}. {e.topicName}
                            </b>
                          </div>
                          <div
                            style={{
                              flex: "1",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              minWidth: "191px",
                              maxWidth: "100%",
                              gap: "20px",
                              fontSize: "10.4px",
                              color: "rgba(40, 57, 139, 0.8)",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "3px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  lineHeight: "14px",
                                  display: "inline-block",
                                  minWidth: "54px",
                                }}
                              >
                                Batch Average Score
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: "18.7px",
                                  lineHeight: "24px",
                                  color: "#28398b",
                                  display: "inline-block",
                                  minWidth: "50px",
                                }}
                              >
                                {e.batchAverageScore}%
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "3px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  lineHeight: "14px",
                                  display: "inline-block",
                                  minWidth: "60px",
                                }}
                              >
                                Batch Top Score
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: "18.7px",
                                  lineHeight: "24px",
                                  color: "#28398b",
                                  display: "inline-block",
                                  minWidth: "36px",
                                }}
                              >
                                {e.batchTopScore}%
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "3px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  lineHeight: "14px",
                                  display: "inline-block",
                                  minWidth: "49px",
                                }}
                              >
                                Your Score
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: "18.7px",
                                  lineHeight: "24px",
                                  color: "#28398b",
                                  display: "inline-block",
                                  minWidth: "50px",
                                }}
                              >
                                {e.studentScore}%
                              </div>
                            </div>
                            <div
                              style={{
                                height: "41.1px",
                                width: "27px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "3.1px",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        style={{
          alignSelf: "stretch",
          backgroundColor: "#fefeff",
          border: "10px solid #28398b",
          boxSizing: "border-box",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          padding: "23px 0px 0px",
          gap: "17.3px",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "19px",
          color: "rgba(0, 0, 0, 0.9)",
          fontFamily: "Cardo",
          height: "841px",
        }}
      >
        <div
          style={{
            width: "579.1px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: "0px 36px",
            boxSizing: "border-box",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "36px",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div
                style={{
                  width: "258px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "1.2px 0px 0px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "19.3px",
                  }}
                >
                  <h2
                    style={{
                      margin: "0",
                      position: "relative",
                      fontSize: "inherit",
                      lineHeight: "101%",
                      fontWeight: "700",
                      fontFamily: "inherit",
                    }}
                  >
                    Batch Average V/s Your Score
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "25px",
                      fontSize: "12.5px",
                      color: "#464646",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "7px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0",
                          height: "13.2px",
                          width: "12.2px",
                          position: "relative",
                          border: "1.2px solid #000",
                          boxSizing: "border-box",
                          backgroundColor: "rgb(40, 57, 139)",
                          borderRadius: 2,
                        }}
                      />
                      <b
                        style={{
                          position: "relative",
                          lineHeight: "13px",
                          fontWeight: "700",
                          display: "inline-block",
                          minWidth: "83px",
                        }}
                      >
                        Batch Average
                      </b>
                    </div>
                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "6px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0",
                          height: "13.2px",
                          width: "12.2px",
                          position: "relative",
                          border: "1.2px solid #000",
                          boxSizing: "border-box",
                          backgroundColor: "rgb(120, 137, 219)",
                          borderRadius: 2,
                        }}
                      />
                      <b
                        style={{
                          flex: "1",
                          position: "relative",
                          lineHeight: "13px",
                          fontWeight: "700",
                          flexShrink: "0",
                        }}
                      >
                        Your Score
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <img
                style={{
                  height: "42.5px",
                  width: "97.5px",
                  position: "relative",
                }}
                alt=""
                src={require("./cert-assets/vector.png")}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 4px 0px 3px",
                boxSizing: "border-box",
                maxWidth: "100%",
                textAlign: "right",
                fontSize: "12.6px",
                color: "#464646",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "7.9px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "30.1px 0px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: "36.5px",
                      marginTop: "18px",
                    }}
                  >
                    <b
                      style={{
                        position: "relative",
                        lineHeight: "13px",
                        fontWeight: "700",
                        display: "inline-block",
                        minWidth: "20px",
                      }}
                    >
                      100
                    </b>
                    <b
                      style={{
                        width: "13.8px",
                        position: "relative",
                        lineHeight: "13px",
                        fontWeight: "700",
                        display: "inline-block",
                        minWidth: "13.8px",
                      }}
                    >
                      80
                    </b>
                    <b
                      style={{
                        width: "13.8px",
                        position: "relative",
                        lineHeight: "13px",
                        fontWeight: "700",
                        display: "inline-block",
                        minWidth: "13.8px",
                      }}
                    >
                      60
                    </b>
                    <b
                      style={{
                        width: "13.8px",
                        position: "relative",
                        lineHeight: "13px",
                        fontWeight: "700",
                        display: "inline-block",
                        minWidth: "13.8px",
                      }}
                    >
                      40
                    </b>
                    <div
                      style={{
                        width: "13.8px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "28px",
                      }}
                    >
                      <b
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "13px",
                          fontWeight: "700",
                          display: "inline-block",
                          minWidth: "13.8px",
                        }}
                      >
                        20
                      </b>
                      <b
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "13px",
                          fontWeight: "700",
                        }}
                      >
                        0
                      </b>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "8.5px",
                    minWidth: "306px",
                    maxWidth: "100%",
                    fontFamily: "Inika",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      position: "relative",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      style={{
                        height: "297.7px",
                        width: "4px",
                        position: "absolute",
                        marginTop: "-9px",

                        left: "1.3px",
                      }}
                      loading="lazy"
                      alt=""
                      src={require("./cert-assets/vector-2818.png")}
                    />
                    <div
                      style={{
                        height: "297.7px",
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "9.7px",
                        maxWidth: "100%",
                        zIndex: "1",
                      }}
                    >
                      <div
                        style={{
                          width: "49.8px",
                          height: "266.1px",
                          display: "none",
                        }}
                      />

                      <div
                        style={{
                          width: "471px",
                          height: "266.1px",
                          position: "relative",
                          overflowX: "auto",
                          flexShrink: "0",
                          maxWidth: "100%",
                          zIndex: "2",
                          display: "flex",
                          flexDirection: "row",
                          paddingLeft:
                            data?.data.topicWiseAnalysis.length === 5
                              ? "30px"
                              : "8px",
                          gap:
                            data?.data.topicWiseAnalysis.length === 5
                              ? "9px"
                              : "2px",
                        }}
                      >
                        {data?.data.batchTopicWiseAnalysis.map((e, index) => {
                          console.log(
                            data.data.topicWiseAnalysis[index].percentage,
                            e.batchAverageScore * 3
                          );
                          return (
                            <div
                              style={{
                                height: "266.1px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  width: "20px",
                                  position: "relative",
                                  backgroundColor: "#28398b",
                                  border: "1.3px solid #000",
                                  boxSizing: "border-box",
                                  opacity: "0",
                                }}
                              />

                              <div
                                style={{
                                  height: `${e.batchAverageScore * 2.4}px`,
                                  width: "29.3px",
                                  position: "relative",
                                  backgroundColor: "#28398b",
                                  border: "1.3px solid #000",
                                  boxSizing: "border-box",
                                }}
                              />

                              <div
                                style={{
                                  height: `${
                                    data.data.topicWiseAnalysis[index]
                                      .percentage * 2.41
                                  }px`,
                                  width: "22.7px",
                                  position: "relative",
                                  backgroundColor: "#7889db",
                                  border: "1.3px solid #000",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <img
                        style={{
                          alignSelf: "stretch",
                          height: "297.7px",
                          position: "absolute",
                          maxWidth: "100%",
                          overflow: "hidden",
                          flexShrink: "0",
                        }}
                        loading="lazy"
                        alt=""
                        src={require("./cert-assets/vector-2817.png")}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                      padding: "0px 36px 0px 44px",
                      boxSizing: "border-box",
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0px 1px 0px 0px",
                        boxSizing: "border-box",
                        maxWidth: "100%",
                        gap: "15px",
                        paddingLeft: "-10px",
                      }}
                    >
                      {data?.data.batchTopicWiseAnalysis.map((e, index) => {
                        return (
                          <div
                            style={{
                              position: "relative",
                              lineHeight: "13px",
                              display: "inline-block",
                              minWidth: "60px",
                              textAlign: "center",
                            }}
                          >
                            {e.topicName}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer
          style={{
            alignSelf: "stretch",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",

            justifyContent: "flex-end",
            padding: "29px 40px 26.7px",
            boxSizing: "border-box",
            gap: "15.7px",
            maxWidth: "104%",
            flexShrink: "0",
            textAlign: "left",
            fontSize: "10px",
            color: "#fff",
            fontFamily: "Inika",
          }}
        >
          <a
            href="https://nationalfinanceolympiad.com/"
            style={{
              textDecoration: "underline",
              lineHeight: "17.7px",
              color: "#28398b",
              zIndex: "1",
              position: "absolute",
              bottom: 20,
            }}
          >
            https://nationalfinanceolympiad.com/
          </a>
        </footer>
      </section>
    </div>
  );
};

export default FinalReport;
