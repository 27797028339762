import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CircularProgress } from "@mui/material";

import NFORecordedHeader from "./NFORecordedComponents/NFORecordedHeader";
import RecordedDetails from "./NFORecordedComponents/RecordedDetails";

import "./NFORecorded.css";
import {
  forward10,
  muteIcon,
  pause,
  playButton,
  reverse10,
  sound,
} from "../../Assets/svgs";
import YouTubePlayer from "../Dashboard/DashboardComponents/VideoCard/VideoCard";
import ErrorModal from "./NFORecordedComponents/ErrorModal";
import { getStoreItems, StoreItems } from "../../Utils/api/auth";
import { AuthContext } from "../../App";

const ERROR_TIMEOUT = 5000;

interface Topic {
  id: number;
  name: string;
  description: string;
  duration: string;
  url?: string;
}

const NFORecorded = () => {
  const [playing, setPlaying] = useState(false);
  const [totalTime, setTotalTime] = useState<number | void>(0);
  const [progress, setProgress] = useState<number | void>(0);
  const [mute, setMute] = useState(false);
  const [storeItems, setStoreItems] = useState<StoreItems[]>([]);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasProceed, setHasProceed] = useState(false);
  const [errorProceed, setErrorProceed] = useState(false);
  const [metadata, setMetadata] = useState<Topic[]>([]);
  const [seekSeconds, setSeekSeconds] = useState(0);
  const [rewind, setRewind] = useState(false);
  const [forward, setForward] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const {
    auth: { student_username, grade },
  } = useContext(AuthContext);

  function secondsToMinutes(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  const openInSameTab = (url: string) => (window.location.href = url);
  const [currentTopicName, setCurrentTopicName] = useState("");

  function getGradeId(grade: number) {
    switch (true) {
      case grade >= 6 && grade <= 8:
        return 32;
      case grade >= 9 && grade <= 10:
        return 34;
      case grade >= 11 && grade <= 12:
        return 35;
      default:
        throw new Error("Invalid grade");
    }
  }

  const handleProced = async () => {
    setHasProceed(true);
    try {
      const token = localStorage.getItem("accessToken");
      if (token) {
        openInSameTab(
          `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${storeItems[0].id}&token=${token}&userName=${student_username}`
        );
      } else handleErrorProceed();
    } catch (error) {
      handleErrorProceed();
    }
  };

  const handleErrorProceed = () => {
    setErrorProceed(true);
    setHasProceed(false);

    setTimeout(() => {
      setErrorProceed(false);
    }, ERROR_TIMEOUT);
  };

  console.log(getGradeId(grade));

  const handleFetchShelfItems = () => {
    if (storeItems.length > 0) {
      console.log("Store items already fetched:", storeItems);
      return;
    }

    getStoreItems()
      .then((res) => {
        setIsLoading(true);
        console.log("Fetched store items response:", res);
        //@ts-ignore
        if (res?.data?.status === "Success") {
          //@ts-ignore
          const items = res.data.data.items;
          const recordedSessions = items.filter(
            (e: StoreItems) => e.id === getGradeId(grade)
          );

          if (recordedSessions.length > 0) {
            console.log("Recorded sessions found:", recordedSessions);
            setStoreItems(recordedSessions);
            const firstSession = recordedSessions[0];
            const newVideoId = firstSession.link.split("=")[1];
            const locked = recordedSessions[0].locked;

            setIsLocked(locked);

            if (firstSession.metadata) {
              try {
                const decodedMetadata = atob(firstSession.metadata);
                const parsedMetadata: Topic[] = JSON.parse(decodedMetadata);
                setMetadata(parsedMetadata);
                const unlockedVideoId = parsedMetadata[0]?.url?.split("=")[1];

                if (newVideoId !== videoId && locked) {
                  setVideoId(newVideoId);
                } else {
                  setVideoId(unlockedVideoId || null);
                  setCurrentTopicName(parsedMetadata[0]?.name || "");
                }
              } catch (error) {
                console.error("Failed to parse metadata:", error);
              }
            }
            setIsLoading(false);
          } else {
            console.log("No recorded sessions found.");
            setStoreItems([]);
            setIsLoading(false);
          }
        } else {
          setStoreItems([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch store items:", error);
        setStoreItems([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchShelfItems();
  }, []);

  console.log(metadata);

  useEffect(() => {
    console.log("Video ID changed:", videoId);
    if (videoId) {
      setPlaying(false);
      setTimeout(() => {
        setPlaying(true);
        setPlaybackSpeed(1);
        setProgress(0);
      }, 100);
    }
  }, [videoId]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLoading]);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e: any) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <ErrorModal display={errorProceed} />

      <motion.div
        className="nr-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <NFORecordedHeader name={storeItems[0]?.name} />
        <div className="nr-page-body">
          <div className="nr-container">
            <div>
              <p className="nr-container-heading">
                {storeItems[0]?.locked
                  ? "A preview from one of our sessions."
                  : currentTopicName}
              </p>
            </div>
            <div
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
              className="nr-player"
            >
              <div id="no_right_click" className="player-video">
                {videoId && (
                  <YouTubePlayer
                    isPlaying={(value) => {
                      if (value) {
                        setPlaying(true);
                      } else {
                        setPlaying(false);
                      }
                    }}
                    style={{ height: isMobile ? "32vh" : "58vh" }}
                    rewind={rewind}
                    forward={forward}
                    onVideoEnd={() => {
                      console.log("Video ended.");
                    }}
                    videoDuration={(time) => {
                      console.log("Video duration set:", time);
                      setTotalTime(time);
                    }}
                    play={playing}
                    pause={!playing}
                    seekSeconds={seekSeconds}
                    videoId={videoId}
                    mute={mute}
                    autoplay
                    rel={0}
                    currentTime={(time: number | void | 0) => {
                      console.log("Current time updated:", time);
                      setProgress(time);
                    }}
                    playbackSpeed={playbackSpeed}
                  />
                )}
              </div>
              <div className="player-controls">
                <div className="player-controls-left">
                  <div
                    className="player-controls-play player-controls-icon"
                    onClick={() => setPlaying((prev) => !prev)}
                  >
                    <img src={playing ? pause : playButton} alt="play" />
                  </div>
                  <div className="player-timeline">
                    <div className="player-timeline-progress">
                      {progress !== void 0 ? secondsToMinutes(progress) : ""}
                    </div>
                    <span>/</span>
                    <div className="player-timeline-total">
                      {totalTime !== void 0 ? secondsToMinutes(totalTime) : ""}
                    </div>
                  </div>
                  <div
                    className="player-controls-icon"
                    onClick={() => {
                      setRewind(true);
                      setTimeout(() => {
                        setRewind(false);
                      }, 100);
                    }}
                  >
                    <img src={reverse10} alt="play" />
                  </div>
                  <div
                    className="player-controls-icon"
                    onClick={() => {
                      setForward(true);
                      setTimeout(() => {
                        setForward(false);
                      }, 100);
                    }}
                  >
                    <img src={forward10} alt="play" />
                  </div>
                  <div
                    className="player-controls-icon"
                    onClick={() => {
                      if (playbackSpeed < 2) {
                        setPlaybackSpeed(playbackSpeed + 0.25);
                      } else {
                        setPlaybackSpeed(1);
                      }
                    }}
                  >
                    <div className="player-controls-playback-speed">
                      {playbackSpeed}x
                    </div>
                  </div>
                </div>
                <div className="player-controls-right">
                  <div
                    onClick={() => {
                      setMute(!mute);
                    }}
                    className="player-volume player-controls-icon"
                  >
                    <img src={mute ? muteIcon : sound} alt="volume" />
                  </div>
                </div>
              </div>
            </div>
            <RecordedDetails
              setCurrentTopicName={(name: string) => {
                setCurrentTopicName(name);
              }}
              setVideoId={(videoId: string | null) => {
                setVideoId(videoId);
              }}
              sessionDetails={metadata}
              name={storeItems[0]?.name}
              description={storeItems[0]?.description}
              currentVideoId={videoId}
              locked={storeItems[0]?.locked}
            />
          </div>
          <AnimatePresence>
            {isLocked && (
              <motion.div
                className="nr-proceed-container shadow-light"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: "0%" }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  damping: 10,
                }}
              >
                <p className="nr-proceed-heading">{storeItems[0]?.name}</p>
                <div className="line"></div>
                <div className="nr-proceed-body">
                  <p className="nr-proceed-price">
                    <span>Subtotal (1 item):</span>
                    <div>
                      <span
                        style={{
                          textDecoration: "line-through",
                          margin: "0px 5px 0px 0px",
                        }}
                      >
                        &#8377;{storeItems[0]?.base_price}
                      </span>
                      &#8377;
                      {storeItems[0]?.selling_price}
                    </div>
                  </p>
                  <span className="text-depressed">inclusive of all taxes</span>
                </div>
                <button
                  type="button"
                  className="nr-proceed-button"
                  disabled={hasProceed}
                  onClick={handleProced}
                >
                  {hasProceed ? (
                    <div
                      style={{
                        width: "fit-content",
                        height: "25px",
                        margin: "auto",
                        color: "#fff",
                      }}
                    >
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    "Proceed"
                  )}
                </button>
                <p className="nr-proceed-footer text-depressed">
                  You will gain access to the recorded sessions after the
                  payment is completed.
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default NFORecorded;
