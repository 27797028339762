import { FunctionComponent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../App";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import {
  certificateItems,
  FeedbackData,
  getCertificateItems,
  getFeedbackQuestions,
  submitFeedbackAnswers,
} from "../../../../Utils/api/auth";
import FinalReport from "./FinalReport";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { backArrowImage } from "../../../../Assets/images";
import { useNavigate } from "react-router";
import Modal from "../../../../Components/modal/Modal";
import Carousel from "../../../../Components/carousel/Carousel";

const ReportViewer = () => {
  const {
    auth: { student_username, report_locked, individualRegistration },
  } = useContext(AuthContext);
  console.log({ student_username, report_locked });
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(true);

  const getFeedbackQuestionsApiCall = async () => {
    setLoader(true);
    getFeedbackQuestions()
      .then((response) => {
        //@ts-ignore
        if (response.data.status === "Success") {
          //@ts-ignore
          setFeedbackQuestions(response.data.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (report_locked) {
      console.log(report_locked === true);
      getFeedbackQuestionsApiCall();
    } else {
      getCertificateData();
    }
  }, [report_locked]);

  console.log(feedbackQuestions);
  const [data, setData] = useState<certificateItems>();

  const getCertificateData = async () => {
    setLoader(true);
    getCertificateItems(student_username).then((response) => {
      //@ts-ignore
      if (response.data.status === "Success") {
        //@ts-ignore
        setData(response.data);
      }
      setLoader(false);
    });
  };

  const submitFeedbackQuestions = async (values: FeedbackData) => {
    setLoader(true);
    submitFeedbackAnswers(values)
      .then((response) => {
        //@ts-ignore
        if (response.data.data.status === "Success") {
          setModalOpen(false);
          setLoader(false);
        } else {
          setModalOpen(false);
          setLoader(false);
        }
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        alert("Unable to load Report Now. Please try again later.");
      });
  };

  const [loader, setLoader] = useState(false);
  const options: Options = {
    filename: data?.data.studentUsername,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.LOW,

    page: {
      margin: Margin.NONE,
      format: "A4",
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // customize any value passed to the jsPDF instance and html2canvas
    // function
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
        orientation: "portrait",
      },

      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
        scale: 3,
      },
    },
  };

  const navigation = useNavigate();
  const openPDF = () => {
    setLoader(true);
    generatePDF(() => document.getElementById("wrapper"), options).then(() => {
      setLoader(false);
    });
  };

  return (
    <div style={{ backgroundColor: "#F1FAFE", flex: 1 }}>
      {report_locked === true && feedbackQuestions.length > 1 && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h2 style={{ color: "rgba(44, 106, 177, 1)" }}>User Feedback</h2>
          <Carousel
            submitButtonText={"View Report"}
            questions={feedbackQuestions}
            onSubmit={(values) => {
              submitFeedbackQuestions(values);
            }}
          />
        </Modal>
      )}
      {data && (
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={backArrowImage}
              alt=""
              onClick={() => {
                navigation("/dashboard");
              }}
              style={{
                cursor: "pointer",
                marginBottom: "5px",
                position: "absolute",
                left: "4%",
                top: "5.5%",
              }}
            />
            <LoadingButton
              variant="contained"
              loading={loader}
              onClick={openPDF}
              style={{
                marginTop: 50,
                marginBottom: 50,
                height: 40,
              }}
            >
              Download Report
            </LoadingButton>

            <FinalReport
              individualRegistration={individualRegistration}
              id={"wrapper"}
              data={data}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportViewer;
