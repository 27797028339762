import React, { useState } from "react";
import { motion } from "framer-motion";

import "./RecordedDetails.css";
import { playButton, downArrow } from "../../../Assets/svgs";

interface Topic {
  id: number;
  name: string;
  description: string;
  duration: string;
  url?: string;
}

interface RecordedDetailsProps {
  sessionDetails: Topic[];
  setVideoId: (id: string) => void;
  name: string;
  description: string;
  currentVideoId: string | null;
  locked: boolean;
  setCurrentTopicName: (name: string) => void;
}

const RecordedDetails: React.FC<RecordedDetailsProps> = ({
  sessionDetails,
  setVideoId,
  name,
  description,
  currentVideoId,
  locked,
  setCurrentTopicName,
}) => {
  const [selectedTopic, setSelectedTopic] = useState<number | null>(0);
  const [showAll, setShowAll] = useState<boolean>(false);
  const initialDisplayCount = 6;

  const toggleSelect = (topicIndex: number) => {
    const newSelectedTopic = topicIndex === selectedTopic ? null : topicIndex;
    setSelectedTopic(newSelectedTopic);
    if (newSelectedTopic !== null) {
      setCurrentTopicName(sessionDetails[newSelectedTopic].name);
    } else {
      setCurrentTopicName("");
    }
  };

  const parseDuration = (duration: string) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const totalDuration = sessionDetails.reduce((total, topic) => {
    const durationInSeconds = parseDuration(topic.duration);
    return total + durationInSeconds;
  }, 0);

  const hours = Math.floor(totalDuration / 3600);
  const minutes = Math.floor((totalDuration % 3600) / 60);
  const seconds = totalDuration % 60;
  const totalDurationString = `${
    hours > 0 ? `${hours}h ` : ""
  }${minutes}m ${seconds}s`;

  return (
    <div className="nrd-container">
      <div className="nrd-header">
        <p className="nrd-header-1">{name}</p>
        <p className="nrd-header-2">{description}</p>
        <div className="nrd-header-btm">
          <div className="nrd-header-btm-div">
            Number of Sessions: {sessionDetails.length}
          </div>
          <div className="nrd-header-btm-div">
            Duration: {totalDurationString}
          </div>
        </div>
      </div>

      <motion.div
        className="nrd-body"
        layout
        style={{ overflow: "hidden", opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
      >
        {sessionDetails
          .slice(0, showAll ? sessionDetails.length : initialDisplayCount)
          .map((topic, index) => (
            <div key={index}>
              <div className="line"></div>
              <div
                style={{
                  backgroundColor:
                    currentVideoId === topic.url?.split("=")[1]
                      ? "rgba(44, 106, 177, 1)"
                      : "rgba(44, 106, 177, 0.1)",
                  color:
                    currentVideoId === topic.url?.split("=")[1]
                      ? "#FFFFFF"
                      : "rgba(44, 106, 177, 1)",
                }}
                className="topic"
              >
                <div className="topic-heading">
                  {topic.name.length > 0 && (
                    <div
                      onClick={() => {
                        toggleSelect(index);
                      }}
                      className="topic-name"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>
                          {index + 1}. {topic.name}
                        </span>
                        {topic.description.length > 0 && (
                          <motion.img
                            src={downArrow}
                            alt="arrow"
                            className="topic-name-arrow"
                            animate={{
                              rotate: selectedTopic === index ? 180 : 0,
                            }}
                            transition={{ duration: 0.3 }}
                          />
                        )}
                      </div>
                      {currentVideoId === topic.url?.split("=")[1] && (
                        <div className="topic-name-lock">Now Playing</div>
                      )}
                    </div>
                  )}

                  <div className="topic-duration-container">
                    {!locked && currentVideoId !== topic.url?.split("=")[1] && (
                      <div
                        onClick={() => {
                          toggleSelect(index);
                          const videoId =
                            sessionDetails[index]?.url?.split("=")[1];
                          console.log("Setting video ID:", videoId);
                          if (videoId) {
                            setVideoId(videoId);
                          }
                        }}
                        className="topic-duration"
                      >
                        <img
                          src={playButton}
                          alt="play"
                          className="topic-duration-play"
                        />
                      </div>
                    )}

                    <span>Duration: {topic.duration}</span>
                  </div>
                </div>
                {topic.description.length > 0 && (
                  <motion.ul
                    initial={{ height: 0, opacity: 0 }}
                    animate={{
                      height: selectedTopic === index ? "auto" : 0,
                      opacity: selectedTopic === index ? 1 : 0,
                    }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.3,
                    }}
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginLeft: 0,
                      marginBlockStart: "0em",
                      marginBlockEnd: "0em",
                      paddingInlineStart: "20px",
                    }}
                  >
                    {topic.description.split("\n").map((detail, ind2) => (
                      <li
                        style={{
                          color:
                            currentVideoId === topic.url?.split("=")[1]
                              ? "#FFFFFF"
                              : "rgba(44, 106, 177, 1)",
                        }}
                        className="sub-topic"
                        key={ind2}
                      >
                        {detail}
                      </li>
                    ))}
                  </motion.ul>
                )}
              </div>
            </div>
          ))}
      </motion.div>

      {sessionDetails.length > initialDisplayCount && (
        <div
          style={{
            alignSelf: "center",
            margin: "1rem 0rem",
            cursor: "pointer",
          }}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "Show More"}
        </div>
      )}
    </div>
  );
};

export default RecordedDetails;
