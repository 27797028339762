import React from "react";

import "./NationalsBadge.css";
import { NFOLogoGreen } from "../../../../Assets/images";

interface PropsType {
  txt: string;
  height?: string;
}

const NationalsBadge: React.FC<PropsType> = ({ txt, height = "60px" }) => {
  return (
    <div className="badge-container">
      <p className="badge-txt">{txt}</p>
      <img
        src={NFOLogoGreen}
        alt="logo"
        height={height}
        className="badge-img"
      />
    </div>
  );
};

export default NationalsBadge;
