import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./HTMLViewer.css";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation, useNavigate } from "react-router";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import {
  certificateItems,
  FeedbackData,
  getCertificateItems,
  getFeedbackQuestions,
  submitFeedbackAnswers,
} from "../../../../Utils/api/auth";
import { AuthContext } from "../../../../App";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import OutstandingCertificate from "./OutstandingCertificate";
import { backArrowImage } from "../../../../Assets/images";
import Modal from "../../../../Components/modal/Modal";
import Carousel from "../../../../Components/carousel/Carousel";

interface ParticipationCertificateProps {
  id: string;
  data: certificateItems;
  individualRegistration: boolean;
}

const ParticipationCertificate: FunctionComponent<
  ParticipationCertificateProps
> = ({ id, data, individualRegistration }) => {
  const { examDate } = data?.data;

  const getExamDateFromUnix = (dateValue: Date) => {
    let date = new Date(dateValue);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Format the date as "DD Month YYYY"
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  const calculateFontSize = (name: string) => {
    const baseSize = 50;
    if (!name) return baseSize;

    if (name.length > 20) {
      return baseSize - Math.min(Math.floor((name.length - 20) * 2), 20);
    }
    return baseSize;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <div>
        <div
          id={id}
          style={{
            width: "847px",
            position: "relative",
            backgroundColor: "#f1fafe",
            border: "20px solid #2c6ab1",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "43px 21px 25px 20px",

            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            fontSize: "18px",
            color: "#000",
            fontFamily: "Cardo",
          }}
        >
          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "24px",
              maxWidth: "100%",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 20px 0px 21px",
              }}
            >
              <img
                style={{
                  height: "70.5px",
                  width: "159.9px",
                  position: "relative",
                }}
                loading="lazy"
                src={require("./cert-assets/vector.png")}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                maxWidth: "100%",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "30px",
                  lineHeight: "92%",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  color: "#2c6ab1",
                }}
              >
                Certificate of Participation
              </h2>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "0px 22px 0px 23px",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: "6px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "0px 40px",
                    }}
                  >
                    <img
                      style={{
                        height: "3px",
                        width: "239px",
                        position: "relative",
                      }}
                      loading="lazy"
                      alt=""
                      src={require("./cert-assets/Vector-linw.png")}
                    />
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 34px",
                      color: "#183041",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "0px 0px 6.7px",
                        gap: "2.3px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0px 0px 0px 0px",
                        }}
                      >
                        <b style={{ position: "relative", lineHeight: "29px" }}>
                          This is to certify
                        </b>
                      </div>
                      <h1
                        style={{
                          margin: "0",
                          position: "relative",
                          fontSize: `${calculateFontSize(data?.data.name)}px`,
                          lineHeight: "70px",
                          fontWeight: "700",
                          fontFamily: "inherit",
                          alignSelf: "center",
                          width: "500px",
                        }}
                      >
                        {`${data?.data.name}`}
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <i
                        style={{
                          position: "relative",
                          lineHeight: "139%",
                          display: "inline-block",
                          fontStyle: "italic",
                          minWidth: "62px",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >{`Of grade ${data?.data?.grade} of ${data?.data.school}`}</i>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "139%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >{`participated in the ${
                    individualRegistration ? "Level-I" : "intra school level"
                  } of the `}</div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  padding: "0px 0px 0px 0px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      height: "25px",
                      position: "relative",
                      lineHeight: "139%",
                      display: "flex",
                      width: "370px",
                    }}
                  >{`National finance olympiad held on ${getExamDateFromUnix(
                    data?.data.examDate
                  )}`}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "364px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 6px 0px 0px",
              boxSizing: "border-box",
              maxWidth: "100%",
              fontSize: "13.8px",
              marginTop: "20px",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                height: "50px",

                width: "100px",
              }}
              src={require("./cert-assets/Vector 2734.png")}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0px 28px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "19px",
                    fontWeight: "700",
                    display: "inline-block",
                    minWidth: "71px",
                  }}
                >
                  Reeju datta
                </b>
              </div>
              <div
                style={{
                  position: "relative",
                  lineHeight: "19px",
                  display: "inline-block",
                  minWidth: "128px",
                }}
              >
                Co-founder, Cashfree
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HTMLViewer = () => {
  const {
    auth: { student_username, report_locked, individualRegistration },
  } = useContext(AuthContext);

  const [data, setData] = useState<certificateItems>();
  const [loader, setLoader] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(true);

  const getFeedbackQuestionsApiCall = async () => {
    setLoader(true);
    getFeedbackQuestions()
      .then((response) => {
        //@ts-ignore
        if (response.data.status === "Success") {
          //@ts-ignore
          setFeedbackQuestions(response.data.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (report_locked) {
      console.log(report_locked === true);
      getFeedbackQuestionsApiCall();
    } else {
      getCertificateData();
    }
  }, [report_locked]);

  const getCertificateData = async () => {
    setLoader(true);
    getCertificateItems(student_username).then((response) => {
      //@ts-ignore
      if (response.data.status === "Success") {
        //@ts-ignore
        setData(response.data);
      }
      setLoader(false);
    });
  };

  const submitFeedbackQuestions = async (values: FeedbackData) => {
    setLoader(true);
    submitFeedbackAnswers(values)
      .then((response) => {
        //@ts-ignore
        if (response.data.data.status === "Success") {
          setModalOpen(false);
          setLoader(false);
        } else {
          setModalOpen(false);
          setLoader(false);
        }
      })
      .then(() => {
        window.location.reload();
      });
  };

  const openPDF = () => {
    setLoader(true);
    generatePDF(() => document.getElementById("wrapper"), options).then(() => {
      setLoader(false);
    });
  };

  const options: Options = {
    filename: data?.data?.studentUsername,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,

    page: {
      margin: Margin.SMALL,
      format: "A4",
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // customize any value passed to the jsPDF instance and html2canvas
    // function
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
        orientation: "landscape",
      },

      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
        scale: 4,
      },
    },
  };
  const navigation = useNavigate();

  return (
    <div style={{ backgroundColor: "#F1faFe" }}>
      {report_locked === true && feedbackQuestions.length > 1 && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h2 style={{ color: "rgba(44, 106, 177, 1)" }}>User Feedback</h2>
          <Carousel
            submitButtonText="View Certificate"
            questions={feedbackQuestions}
            onSubmit={(values) => {
              submitFeedbackQuestions(values);
            }}
          />
        </Modal>
      )}
      {data && (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={backArrowImage}
            alt=""
            onClick={() => {
              navigation("/dashboard");
            }}
            style={{
              cursor: "pointer",
              marginBottom: "5px",
              position: "absolute",
              left: "4%",
              top: "5.5%",
            }}
          />
          <LoadingButton
            variant="contained"
            loading={loader}
            onClick={openPDF}
            style={{
              marginTop: 50,
              marginBottom: 50,
              height: 40,
            }}
          >
            Download Certificate
          </LoadingButton>
          {data?.data?.awardWon === "Participation Certificate" ? (
            <ParticipationCertificate
              id={"wrapper"}
              data={data}
              individualRegistration={individualRegistration}
            />
          ) : (
            <OutstandingCertificate
              id={"wrapper"}
              data={data}
              individualRegistration={individualRegistration}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HTMLViewer;
