import React, { useEffect, useState } from "react";
import VideoModal from "../../../../Components/videoModal/Modal";
import YouTubePlayer from "../VideoCard/VideoCard";
import Lottie from "lottie-react";
import ConfettiLottie from "../../../../Assets/lotties/ConfettiLottie.json";
import { closeIcon, replayIcon } from "../../../../Assets/images";
import { motion } from "framer-motion";
import { getCelebrationShown } from "../../../../Utils/api/auth";
import { playButton } from "../../../../Assets/svgs";

interface CelebrationProps {
  setIsCelebration: (value: boolean) => void;
}

const Celebration = ({ setIsCelebration }: CelebrationProps) => {
  const setConfettiShown = async () => {
    try {
      localStorage.setItem("celebrationShown", "true");
      await getCelebrationShown(true);
      console.log("Celebration shown status updated successfully.");
    } catch (error) {
      console.error("Error updating celebration shown status:", error);
    }
  };

  const [play, setPlay] = useState(true);
  const [pause, setPause] = useState(false);
  const [replay, setReplay] = useState(false);
  const [openControls, setOpenControls] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (replay) {
      setPlay(true);
      setPause(false);
    }
  }, [replay]);
  useEffect(() => {
    setTimeout(() => {
      setIsModalOpen(true);
    }, 500);

    setTimeout(() => {
      setPlay(true);
    }, 900);
  }, []);

  const isMobile = window.innerWidth <= 768;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        height: "100vh",
        width: "100vw",
        top: 0,
        zIndex: 1000,
      }}
    >
      <VideoModal isOpen={isModalOpen} onClose={() => {}}>
        <div style={{ borderRadius: 20, overflow: "hidden" }}>
          <YouTubePlayer
            isPlaying={(value) => {
              if (!value) {
                setPlay(true);
              } else {
                return;
              }
            }}
            style={{ height: isMobile ? "40vh" : "70vh" }}
            rewind={false}
            forward={false}
            onVideoEnd={() => {
              setOpenControls(true);
              setConfettiShown();
            }}
            videoDuration={(time) => {}}
            play={play}
            pause={pause}
            seekSeconds={0}
            videoId={"NgkOpu3nMUc"}
            mute={true}
            autoplay
            rel={0}
            currentTime={(time: number | void | 0) => {
              console.log("Current time updated:", time);
            }}
            playbackSpeed={1}
            replay={replay}
          />
        </div>
        {openControls && (
          <motion.div
            initial={{ opacity: 0.5, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 1,
              position: "absolute",

              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <button
              style={{
                backgroundColor: "rgba(0, 0, 0, 1)",
                border: 0,
                borderRadius: "8px",
                padding: "10px 20px",
              }}
              onClick={() => {
                setReplay(true);
                setTimeout(() => {
                  setReplay(false);
                }, 100);
                setOpenControls(false);
              }}
            >
              <img
                style={{ height: "100px", width: "100px" }}
                src={replayIcon}
                alt="replay"
              />
              <div style={{ color: "white", fontSize: "16px" }}>Replay</div>
            </button>
            <button
              onClick={() => {
                setIsCelebration(false);
              }}
              style={{
                backgroundColor: "rgba(0, 0, 0, 1)",
                border: 0,
                borderRadius: "8px",
                padding: "10px 20px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px" }}
                src={closeIcon}
                alt="replay"
              />
              <div style={{ color: "white", fontSize: "16px" }}>Close</div>
            </button>
          </motion.div>
        )}
      </VideoModal>
      <Lottie
        className="confetti-lottie"
        loop={false}
        animationData={ConfettiLottie}
        autoplay={true}
      />
    </motion.div>
  );
};

export default Celebration;
