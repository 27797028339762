import api from "../axios/axiosMW";

export interface ServerResponseGeneral {
  status: "Success" | "Error";
  status_code: number;
  message: string;
}

export interface sendOtpResponse extends ServerResponseGeneral {}

export interface userData {
  data: {
    created_at: string;
    school_id: number;
    student_username: string;
    name: string;
    phone: string;
    email: string;
    grade: number;
    batch_id: string;
    invite_sent: boolean;
    report_generated: boolean;
    exam_notification_sent: boolean;
    report_id: number;
    paid_for_mock_olympiad: boolean;
    notify_for_new_mock_olympiads: boolean;
    token: string;
    refreshToken: string;
    examDate: string;
    showMockOlympiad: boolean;
    sessionBooked: boolean;
    qualifiedForNationals: boolean;
    showLiveSession: boolean;
    report_locked: boolean;
    showPracticeTestAndQuestionBankBanner: boolean;
    referralActive: boolean;
    isInternational: boolean;
    confetti_shown: boolean;
    showAdvanceBookAndRecordedSessionBanner: boolean;
  };
}

export interface verifyOtpResponse extends ServerResponseGeneral {
  data: userData["data"];
}

export interface getDetailsResponse extends ServerResponseGeneral {
  data: userData;
}

/**
 *
 * @param userId
 * @returns
 */
export function sendOtp(studentUserName: string): Promise<sendOtpResponse> {
  return api.post("user-login-send-otp", {
    studentUserName,
  });
}

/**
 *
 * @param data
 * @returns
 */
export function verifyOtp(data: {
  studentUserName: string;
  otp: string;
}): Promise<verifyOtpResponse> {
  return api.post("user-login-verify-otp", {
    ...data,
  });
}

export function getDetails(
  studentUserName: string
): Promise<getDetailsResponse> {
  return api.post("get-student-details", { studentUserName });
}

interface refreshTokenResponse extends ServerResponseGeneral {
  data: {
    token: string;
    refresh_token: string;
  };
}

export function RefreshToken(
  refreshToken: string | null
): Promise<refreshTokenResponse> {
  return api.post("refresh-token", {
    headers: {
      Authorization: refreshToken,
    },
  });
}

export interface ItemDetails {
  created_at: string;
  updated_at: string;
  name: string;
  image: string;
  subtitle: string;
  description: string;
  link: string;
  hsn: string;
  base_price: number;
  selling_price: number;
  draft: boolean;
  paid: boolean;
  recommended_item: boolean;
  self_checkout_recommended_item: boolean;
  for_user: string;
  id: number;
}
export interface OrderedItems {
  bundle: boolean;
  itemDetails: ItemDetails[];
  invoiceNo: string;
}

export interface getPastOrdersResponse extends ServerResponseGeneral {
  data: {
    orderedItems: OrderedItems[];
  };
}

export function getPastOrders(): Promise<getPastOrdersResponse> {
  return api.post("get-past-orders");
}

export interface StoreItems {
  created_at: string;
  updated_at: string;
  name: string;
  image: string;
  subtitle: string;
  description: string;
  link: string;
  base_price: number;
  selling_price: number;
  draft: boolean;
  paid: boolean;
  recommended_item: boolean;
  id: number;
  locked: boolean;
}

export interface getStoreItemsResponse extends ServerResponseGeneral {
  data: {
    items: StoreItems[];
  };
}

export function getStoreItems(): Promise<getStoreItemsResponse> {
  return api.post("get-store-items");
}

export interface certificateItems extends ServerResponseGeneral {
  data: {
    name: string;
    grade: number;
    school: string;
    city: string;
    examDate: Date;
    zone: string;
    studentUsername: string;
    participatedStudentCount: number;
    awardWon: string;
    scoreAnalysis: ScoreAnalysis;
    topicWiseAnalysis: TopicWiseAnalysis[];
    batchTopicWiseAnalysis: BatchTopicWiseAnalysis[];
  };
}

export interface BatchTopicWiseAnalysis {
  topicName: string;
  batchTopScore: number;
  batchAverageScore: number;
  studentScore: number;
}

export interface ScoreAnalysis {
  marksObtained: number;
  fullMarks: number;
  percentage: number;
  batchRank: number;
  timeTakenForExam: string;
  batchHighestScore: number;
  batchLowestScore: number;
  batchAverageScore: number;
  overallGrade: string;
  overallRemarks: string;
}

export interface TopicWiseAnalysis {
  topicName: string;
  marksObtained: number;
  fullMarks: number;
  percentage: number;
  grade: string;
  remarks: string;
}

export function getCertificateItems(
  studentUserName: string
): Promise<certificateItems> {
  return api.post("get-student-report", { studentUsername: studentUserName });
}

export interface InvoiceResponse {
  OrderNo: string;
  InvoiceNo: string;
  DateOfInvoice: string;
  Products: [
    {
      HSNCode: string;
      Description: string;
      Quantity: number;
      RatePerUnit: number;
      Amount: number;
    }
  ];
  Subtotal: number;
  TotalTax: number;
  InvoiceTotal: number;
  amountInWords: string;
  invoiceTo: string;
  stateOfUser: string;
}

interface ApiGetInvoiceResponse extends ServerResponseGeneral {
  data: InvoiceResponse;
}

export function getInvoiceApi(
  invoiceNo: string
): Promise<ApiGetInvoiceResponse> {
  return api.post("get-invoice-data", { invoiceNo: invoiceNo });
}

// APIs for Doubt clearing session booking
export interface UserSlots {
  data:
    | {
        storeItemID: number;
        datesAvailable: string[];
        dateWiseSlot: {
          [key: string]: {
            starttime: string;
            endtime: string;
            available: boolean;
          }[];
        };
      }
    | string;
  message: string;
}

interface UserSlotsResponse extends ServerResponseGeneral {
  data: UserSlots;
}

export function getUserSlots(): Promise<UserSlotsResponse> {
  return api.post("get-user-slots");
}

export interface SaveBookingDetailsResponse extends ServerResponseGeneral {
  data: {
    bookingDetailsID: string;
  };
}

export function saveBookingDetails(data: {
  questionDescription: string;
  timeSlot: string;
}): Promise<SaveBookingDetailsResponse> {
  return api.post("save-booking-details", {
    ...data,
  });
}

export interface SlotBook {
  data: any;
}

interface SlotBookResponse extends ServerResponseGeneral {
  data: SlotBook;
}

export function bookSlot(data: any): Promise<SlotBookResponse> {
  return api.post("book", data);
}

export interface BookedSlots {
  data: {
    slotsBooked: boolean;
    slotDetails?: [
      {
        created_at: string;
        event_id: string;
        meeting_link: string;
        slot_start_time: string;
        slot_end_time: string;
      }
    ];
  };
  message: string;
}

interface BookedSlotsresponse extends ServerResponseGeneral {
  data: BookedSlots;
}

export function getBookedSlots(): Promise<BookedSlotsresponse> {
  return api.post("get-booked-slots");
}

// NFO Session requests
export interface SessionsAvailableData {
  data: {
    batch_start_time: string;
    event_id: string;
    slot_details: {
      batch_start_time: string;
      batch_end_time: string;
    }[];
  }[];
  message: string;
}

interface PreOlympiadSlotsResponse extends ServerResponseGeneral {
  data: SessionsAvailableData;
}

export function getPreOlympiadSlots(): Promise<PreOlympiadSlotsResponse> {
  return api.post("pre-olympiad-slots");
}

export interface SessionBookedSlots {
  data: {
    batch_start_time: string;
    batch_end_time: string;
    meeting_link: string;
  }[];
  message: string;
}

interface SessionBookedSlotsResponse extends ServerResponseGeneral {
  data: SessionBookedSlots;
}

export function getPreOlympiadBookedSlots(): Promise<SessionBookedSlotsResponse> {
  return api.post("pre-olympiad-booked-slots");
}

// Event Tracking request
export interface LogEventBody {
  studentUserID: string;
  eventName: string;
  loginEvent?: boolean;
  productIDs?: number[];
}

export interface LogEventResponse extends ServerResponseGeneral {
  data: any;
}

export function logEvent(body: LogEventBody): Promise<LogEventResponse> {
  return api.post("log-event", { ...body });
}

export interface feedbackQuestionsBody {
  id: number;
  question_answer: {
    question: string;
  };
  type: "rating";
  draft: boolean;
  is_mandatory: boolean;
}

export interface feedbackQuestionsRepsonse extends ServerResponseGeneral {
  data: feedbackQuestionsBody[];
}

export function getFeedbackQuestions(): Promise<feedbackQuestionsRepsonse> {
  return api.post("get-feedback-questions", {});
}

export interface FeedbackQuestion {
  id: number;
  question_answer: {
    question: string;
    options?: {
      [key: string]: string; // e.g., A, B, C, D
    };
    selected?: string | number; // Present for MCQ questions
  };
  type: "rating" | "mcq" | "text"; // Specify the question types
  rated?: number | string; // Present for rating questions
  answer?: string | number; // Present for text questions
  draft?: boolean; // Indicates if it's a draft
  is_mandatory?: boolean; // Indicates if the question is mandatory
}

export interface FeedbackData {
  feedback: FeedbackQuestion[];
}

export interface submitFeedbackAnswerRepsonse extends ServerResponseGeneral {
  data: "";
}

export function submitFeedbackAnswers(
  feedback: FeedbackData
): Promise<feedbackQuestionsRepsonse> {
  return api.post("submit-feedback", { feedback });
}

interface GetStudentReferrals extends ServerResponseGeneral {
  data: { data: string[] };
}

export function getStudentReferrals(): Promise<GetStudentReferrals> {
  return api.post("get-student-referrals");
}

// NFO Nationals
export interface NationalsStudent {
  data: {
    SchoolName: string;
    email: string;
    examDate: string;
    examLink: string;
    examRules: string;
    grade: number;
    name: string;
    phone: string;
  };
}

interface NationalsGetStudentResponse extends ServerResponseGeneral {
  data: NationalsStudent;
}

export function NationalsGetStudent(
  studentUserName: string
): Promise<NationalsGetStudentResponse> {
  return api.post("nationals-get-student", {
    studentUserName: studentUserName,
  });
}

export function getCelebrationShown(
  watched: boolean
): Promise<NationalsGetStudentResponse> {
  return api.post("update-confetti", {
    watched: watched,
  });
}
